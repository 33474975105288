import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.1.6_@babel+core@7.26.7_react-dom@19.0.0_react@19.0.0__react@1_qk42n6e7ervsopg22gebuarn7q/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@15.1.6_@babel+core@7.26.7_react-dom@19.0.0_react@19.0.0__re_cw3s3edhzpr2pbeundcky6xgom/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.7_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.77.4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.7_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.77.4/node_modules/next/font/local/target.css?{\"path\":\"src/app/(app)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-LightItalic.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-MediumItalic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-SemiBoldItalic.woff2\",\"weight\":\"600\",\"style\":\"italic\"}],\"display\":\"block\"}],\"variableName\":\"circular\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(app)/_client-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(app)/_components/header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
